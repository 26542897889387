import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function FaqItem({ title, childs = [] }) {
  return (
    <MDBox sx={{ color: "#282828" }}>
      <MDTypography fontSize={18} fontWeight="normal" sx={{ color: "#282828" }}>
        {title}
      </MDTypography>
      {childs.map((item) => (
        <>
          <MDTypography fontSize={14} fontWeight="light" mt={1} sx={{ color: "#282828" }}>
            {item.desc}
          </MDTypography>
          {item?.collapse &&
            item?.collapse.map((item2) => (
              <>
                <MDTypography fontSize={14} fontWeight="light" ml={2} sx={{ color: "#282828" }}>
                  {item2?.desc ?? item2}
                </MDTypography>
                {item2?.collapse &&
                  item2?.collapse.map((item3) => (
                    <>
                      <MDTypography
                        fontSize={14}
                        fontWeight="light"
                        ml={4}
                        sx={{ color: "#282828" }}
                      >
                        {item3?.desc ?? item3}
                      </MDTypography>
                      {item3?.collapse &&
                        item3?.collapse.map((item4) => (
                          <MDTypography
                            fontSize={14}
                            fontWeight="light"
                            ml={6}
                            sx={{ color: "#282828" }}
                          >
                            {item4}
                          </MDTypography>
                        ))}
                    </>
                  ))}
              </>
            ))}
        </>
      ))}
      <br />
    </MDBox>
  );
}

export default FaqItem;
