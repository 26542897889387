import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { listDataFaq } from "pages/terms-page/constants/data";
import FaqItem from "../FaqItem";

function Main() {
  return (
    <MDBox p={60 / 8}>
      <MDBox>
        <MDTypography
          fontWeight="medium"
          fontSize={{ xs: 24, md: 36, lg: 54 }}
          sx={{ color: "#282828" }}
        >
          DEAL DASHBOARD
        </MDTypography>
        <MDTypography fontWeight="regular" fontSize={14} sx={{ color: "#282828" }}>
          Frequently Asked Questions (FAQ)
        </MDTypography>
        <MDTypography fontWeight="light" fontSize={14} sx={{ color: "#282828" }}>
          <br />
          <br />
        </MDTypography>
        {listDataFaq.map((faq) => (
          <FaqItem title={faq.title} childs={faq.childs} />
        ))}
      </MDBox>
    </MDBox>
  );
}

export default Main;
