/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components

// Otis Admin PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import FooterImage from "assets/images/icons/pay/footer-img.svg";
import Header from "layouts/pages/pricing-page/components/Header";
import PricingCards from "layouts/pages/pricing-page/components/PricingCards";

//
import MDBox from "components/MDBox";

import useMediaQuery from "@mui/material/useMediaQuery";
import MDTypography from "components/MDTypography";
import AuthMobileLayout from "pages/sign-in/Mobile";
import DescriptionCompSignUp from "pages/sign-up/illustration/DescriptionCompSignUp";
import { useNavigate } from "react-router-dom";
import { useFlatInject } from "store";
import { MDCustomSwitch } from "./components/Header";

function PricingPage() {
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["59", "89", "99"]);
  const [isMonthly, setMonthly] = useState(false);
  const isMobile = useMediaQuery("(max-width:480px)");
  const [user] = useFlatInject("user");
  const navigate = useNavigate();

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "yearly") {
      setPrices(["119", "159", "399"]);
    } else {
      setPrices(["59", "89", "99"]);
    }
  };
  const handleClickLogin = async () => {
    await user.logout();
    window.localStorage.clear();
    navigate("/sign-in");
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isMobile ? (
        <AuthMobileLayout
          px={5}
          isPricing
          description={
            <DescriptionCompSignUp isMobile={isMobile} handleClickLogin={handleClickLogin} />
          }
          setMonthly={setMonthly}
          title={
            <MDTypography
              fontSize="32px"
              fontWeight="regular"
              sx={{
                color: "white !important",
              }}
            >
              Pricing
            </MDTypography>
          }
        >
          <MDBox
            display="flex"
            flexDirection="row"
            justifyContent="center"
            sx={{ flexWrap: "wrap" }}
          >
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="20px"
              pb="6px"
              alignItems="center"
              sx={{ alignSelf: "center", marginLeft: isMobile ? 0 : "132px" }}
            >
              <MDTypography fontWeight="normal" sx={{ color: "#ffffff" }}>
                Pay Monthly
              </MDTypography>
              <MDCustomSwitch
                onChange={(event) => setMonthly(!event.target.checked)}
                sx={{ border: "1px solid #D4AF37", borderRadius: "16px" }}
              />
              <MDTypography fontWeight="normal" sx={{ color: "#ffffff" }}>
                Pay Annually
              </MDTypography>
            </MDBox>
            <MDBox
              sx={{
                background: "#D4AF37",
                padding: "0px 8px",
                borderRadius: "4px",
                height: "fit-content",
                marginTop: "4px",
                marginLeft: "8px",
              }}
            >
              <MDTypography fontWeight="medium" fontSize="16px">
                20% discount
              </MDTypography>
            </MDBox>
            {!isMobile && (
              <MDBox
                sx={{
                  background: "#D4AF37",
                  padding: "0px 8px",
                  borderRadius: "4px",
                  height: "fit-content",
                  marginTop: "4px",
                  marginLeft: "auto",
                }}
              >
                <MDTypography fontWeight="medium" fontSize="16px">
                  20% discount
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
          <MDBox
            sx={{
              fontSize: "16px",
              color: "#fff",
              margin: isMobile ? "36px 0 8px" : "8px 0",
              fontWeight: "500",
              fontStyle: "italic",
              paddingBottom: "48px",
              textAlign: "center",
            }}
          >
            All prices include GST
          </MDBox>
          <PricingCards prices={prices} isMobile={isMobile} landingPage isMonthly={isMonthly} />
          <MDBox
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pb={16.5}
            pt={9}
            sx={{ gap: "24px" }}
          >
            <MDTypography fontSize="18px" color="white">
              Brought to you by
            </MDTypography>
            <MDBox
              component="img"
              src={FooterImage}
              width="390px"
              height="80px"
              sx={({ breakpoints }) => ({
                [breakpoints.up("xs")]: {
                  width: "80%",
                },
              })}
            />
          </MDBox>
        </AuthMobileLayout>
      ) : (
        <PageLayout>
          <Header tabValue={tabValue} tabHandler={handleSetTabValue} setMonthly={setMonthly}>
            <PricingCards prices={prices} isMonthly={isMonthly} />
          </Header>
        </PageLayout>
      )}
    </>
  );
}

export default PricingPage;
