/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import { FormControlLabel, RadioGroup } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import React, { forwardRef, useCallback, useMemo, useRef } from "react";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components

// Data
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDRadio from "components/MDRadio";
import Table from "components/Table";
import { useLocation, useNavigate } from "react-router-dom";
import { useFlatInject } from "store";
import { redirectUrlAfterSaveSection, submitAutoSave } from "utils/common";
import { useAutosave } from "utils/hooks";
import { Message } from "utils/message";
import { Form, FormItem, useForm } from "../../../../../components/Form";

const radioUI = <MDRadio />;
const fieldStyle = { flex: 1 };
const labelSx = { display: "none" };

const QuestionForm = React.forwardRef((props, ref) => {
  const [{ dealDue }] = useFlatInject("dealPlanOperation");
  const form = useForm({
    initialValues: {
      questions: dealDue.questions,
    },
  });
  const { isMobile } = props;

  React.useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  const columns = useMemo(
    () =>
      dealDue.questions.map((q, qindex) => ({
        title: q.title,
        children: [
          {
            key: "item",
            title: "",
            width: 50,
            colSpan: (data) => (!data.row ? "0" : "1"),
            render: (data, index) => (
              <MDTypography sx={{ color: "#757575", verticalAlign: "top", fontSize: "14px" }}>
                {data?.row ?? index + 1}
              </MDTypography>
            ),
            vertiAlign: "top",
          },
          {
            key: "name",
            title: "",
            colSpan: (data) => (!data.row ? "0" : data.name ? "1" : "3"),
            render: (data, index) => (
              <>
                <MDBox sx={{ color: "#282828" }}>{data.name}</MDBox>
                {(data.tips || data.alerts) && (
                  <MDBox display="flex" gap={1} sx={{ marginTop: "11px" }}>
                    <MDTypography
                      sx={{
                        color: "#0E361C",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {data.tips ? "TIP: " : "ALERT: "}
                    </MDTypography>
                    <MDTypography
                      sx={{ color: "#0E361C", fontWeight: "regular", fontSize: "12px" }}
                    >
                      {data.tips ?? data.alerts}
                    </MDTypography>
                  </MDBox>
                )}
              </>
            ),
            vertiAlign: "top",
          },
          {
            key: "value",
            title: "",
            width: 200,
            colSpan: (data) => (data.name === "notes" ? "3" : data.name ? "1" : "0"),
            render: (rowData, index) =>
              rowData.name !== "notes" ? (
                <FormItem as={RadioGroup} name={`questions[${qindex}].children[${index}].value`}>
                  <MDBox display="flex" flexDirection="row">
                    <FormControlLabel
                      value="Y"
                      control={radioUI}
                      label={<MDTypography variant="subtitle2">Yes</MDTypography>}
                    />
                    <FormControlLabel
                      value="N"
                      control={radioUI}
                      label={<MDTypography variant="subtitle2">No</MDTypography>}
                    />
                  </MDBox>
                </FormItem>
              ) : (
                <FormItem
                  as={MDInput}
                  multiline
                  type="text"
                  name={`questions[${qindex}].children[${index}].value`}
                  variant="standard"
                  placeholder="Notes"
                  fullWidth
                />
              ),
          },
        ],
      })),
    []
  );
  const columnsMobile = useMemo(
    () =>
      dealDue.questions.map((q, qindex) => ({
        title: q.title,
        children: [
          {
            key: "item",
            width: "100%",
            render: (data, index) => (
              <MDBox>
                {data.name !== "notes" ? (
                  <MDBox>
                    <MDBox sx={{ display: "flex", gap: "40px", marginBottom: "40px" }}>
                      <MDBox display="flex" flexDirection="column">
                        {/* <MDTypography sx={{ color: "#282828", verticalAlign: "top", fontSize: "12px" }}>
                        Item
                      </MDTypography> */}
                        <MDTypography
                          sx={{ color: "#282828", verticalAlign: "top", fontSize: "16px" }}
                        >
                          {data?.row ?? index + 1}
                        </MDTypography>
                      </MDBox>
                      <MDBox sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        {/* <MDTypography sx={{ color: "#282828", verticalAlign: "top", fontSize: "12px" }}>
                        Name
                      </MDTypography> */}
                        <MDBox sx={{ color: "#282828", fontSize: "16px" }}>{data.name}</MDBox>
                        {(data.tips || data.alerts) && (
                          // <MDBox display="flex" gap={1} sx={{ marginTop: "11px" }}>
                          <MDTypography
                            sx={{
                              color: "#0E361C",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            {data.tips ? "TIP: " : "ALERT: "}
                            <span
                              style={{
                                color: "#0E361C",
                                fontWeight: 400,
                                fontSize: "12px",
                              }}
                            >
                              {data.tips ?? data.alerts}
                            </span>
                          </MDTypography>
                          // </MDBox>
                        )}
                      </MDBox>
                    </MDBox>

                    <FormItem
                      as={RadioGroup}
                      name={`questions[${qindex}].children[${index}].value`}
                      formItemSx={{ paddingBottom: "16px" }}
                    >
                      {/* <MDTypography sx={{ color: "#282828", verticalAlign: "top", fontSize: "12px" }}>
                      Yes / No
                    </MDTypography> */}
                      <MDBox display="flex" flexDirection="row" alignItems="center">
                        <FormControlLabel
                          value="Y"
                          control={radioUI}
                          sx={{ display: "flex", marginLeft: "-9px" }}
                          label={
                            <MDTypography
                              sx={{ color: "#141414", fontSize: "16px", verticalAlign: "center" }}
                            >
                              Yes
                            </MDTypography>
                          }
                        />
                        <FormControlLabel
                          value="N"
                          control={radioUI}
                          sx={{ display: "flex", marginLeft: "-9px" }}
                          label={
                            <MDTypography
                              sx={{ color: "#141414", fontSize: "16px", verticalAlign: "center" }}
                            >
                              No
                            </MDTypography>
                          }
                        />
                      </MDBox>
                    </FormItem>
                  </MDBox>
                ) : (
                  <Grid item xs={12} marginTop={0}>
                    <FormItem
                      as={MDInput}
                      placeholder="Notes"
                      label="Notes"
                      multiline
                      type="text"
                      name={`questions[${qindex}].children[${index}].notes`}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                )}
              </MDBox>
            ),
          },
        ],
      })),
    []
  );
  const uiMemo = useMemo(
    () =>
      (isMobile ? columnsMobile : columns).map((i, idx) => (
        <MDBox key={i.title} sx={{ ...(isMobile && { marginLeft: "-8px" }) }}>
          <MDBox pt={3}>
            <MDTypography
              variant="h6"
              sx={{ ...(isMobile && { fontSize: "18px", color: "#282828", fontWeight: "500" }) }}
            >
              {i.title}:
            </MDTypography>
          </MDBox>
          <Table
            columns={i.children}
            px={isMobile ? 0 : null}
            data={dealDue.questions[idx].children}
            sxTableRow={isMobile ? { display: "none" } : {}}
            sxTitle={isMobile ? { mb: "48px" } : {}}
            sxItem={isMobile ? { mt: "36px", display: "flex" } : {}}
          />
        </MDBox>
      )),
    []
  );
  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      {uiMemo}
    </Form>
  );
});

const DealNotesForm = forwardRef((props, ref) => {
  const [{ dealDue, dealReview }] = useFlatInject("dealPlanOperation");
  const form = useForm({
    initialValues: {
      dealNotes: dealDue.notes,
    },
    onSubmit: props.submit,
  });
  const { isMobile } = props;
  React.useImperativeHandle(ref, () => ({
    getFormData: () => form.values,
    validate: () => form.validateForm(),
  }));
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Form form={form} fieldStyle={fieldStyle} labelSx={labelSx}>
      <MDBox pt={3}>
        <MDTypography
          variant="h6"
          sx={{ ...(isMobile && { fontSize: "18px", color: "#282828", fontWeight: "500" }) }}
        >
          Deal Notes:
        </MDTypography>
      </MDBox>
      <MDBox xs={12} mt={2}>
        <FormItem
          as={MDInput}
          multiline
          type="text"
          name="dealNotes"
          variant="standard"
          placeholder="Enter Deal Notes"
          fullWidth
        />
      </MDBox>
      <Grid mt={4} item xs={12} textAlign="right" display="flex" justifyContent="end">
        <MDButton
          color="green"
          variant="outlined"
          onClick={() => navigate("/deal-planner", { replace: true })}
          sx={{
            mr: 1,
            fontSize: "14px",
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          loading={form.isSubmitting}
          disabled={form.isSubmitting || !dealReview.businessName}
          onClick={form.submitForm}
          color="green"
          sx={{
            border: "1px solid #004225",
            fontSize: "14px",
          }}
        >
          Save and next
        </MDButton>
      </Grid>
    </Form>
  );
});

function DealDue({ isMobile, setTextAutoSave, setSavedSection }) {
  const [{ saveDealDue, setCardOpen, baseInfo }] = useFlatInject("dealPlanOperation");

  const questionFormRef = useRef();
  const dealNotesRef = useRef();
  const location = useLocation();
  const submit = useCallback(
    async (isAuto = false) => {
      const questionsFormData = questionFormRef.current?.getFormData?.();
      const dealNotesRefFormData = dealNotesRef.current?.getFormData?.();
      try {
        await saveDealDue({
          questions: questionsFormData.questions,
          notes: dealNotesRefFormData.dealNotes,
        });
        if (!isAuto) {
          redirectUrlAfterSaveSection(
            setSavedSection,
            `${location.pathname + location.search}#deal-rework`
          );
          Message.success("Changes saved");
        }
        // setCardOpen("final-term-sheet");
      } catch (error) {
        Message.error(error.message);
      }
    },
    [saveDealDue]
  );

  useAutosave(async () => {
    if (baseInfo?.id) {
      submitAutoSave(() => submit(true), setTextAutoSave);
    }
  });

  return (
    <Card
      id="deal-due"
      sx={{
        overflow: "visible",
        boxShadow: isMobile ? "none" : null,
      }}
    >
      {!isMobile && (
        <MDBox p={3}>
          <MDTypography variant="h4">Deal Discovery</MDTypography>
        </MDBox>
      )}
      <MDBox component="form" pb={3} px={isMobile ? 0 : 3}>
        <QuestionForm isMobile={isMobile} ref={questionFormRef} />
        <DealNotesForm isMobile={isMobile} submit={() => submit(false)} ref={dealNotesRef} />
      </MDBox>
    </Card>
  );
}

export default DealDue;
