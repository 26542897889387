import Message from "components/Message";

export const compareFn = (a, b) => {
  const nameA = a.value.toUpperCase();
  const nameB = b.value.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const getCopyText = (parent) => {
  let res = "<ul>";
  const pre = parent.preClosingStrategy;
  const listTextPre = [...pre.table.map((item) => item.yesOrNo === "Y" && item.notes)].map((item) =>
    item ? `<li>${item}</li>` : ""
  );
  res += listTextPre.join("");

  const gap = parent.gapFundingStrategy;
  const listTexGap = [...gap.table.map((item) => item.yesOrNo === "Y" && item.notes)].map((item) =>
    item ? `<li>${item}</li>` : ""
  );
  res += listTexGap.join("");
  res += "</ul>";

  return res;
};

export const submitAutoSave = async (callback, setTextAutoSave, timeCloseText = 5000) => {
  try {
    await callback();
    setTextAutoSave("Changes saved");
    setTimeout(() => {
      setTextAutoSave("");
    }, timeCloseText);
  } catch (error) {
    Message.error(error.message);
  }
};

export const redirectUrlAfterSaveSection = (
  callbackBeforeRedirect = () => {},
  url,
  delayRedirect = 700
) => {
  callbackBeforeRedirect();
  setTimeout(() => {
    window.location.href = url;
  }, delayRedirect);
};
